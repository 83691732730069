/** 产品申请框 */
<template>
  <el-dialog :width="$isPc?'50%':'100%'" title="申请金融产品" custom-class="border-r6" :visible.sync="showApply" center
    :modal-append-to-body='false' :before-close="handleClose">
    <ProductForm v-bind="$attrs" @handleClose="handleClose" />
  </el-dialog>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import ProductForm from './Pform.vue'
export default {
  name: 'ProductApply',
  components: {
    ProductForm
  },
  data () {
    return {}
  },
  computed: {
    ...mapGetters([
      'showApply',
    ])
  },
  methods: {
    ...mapActions([
      'setShowApply',
    ]),
    // dialog关闭前的回调
    handleClose () {
      this.setShowApply(false)
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/ .product-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .el-form-item {
    width: 48%;
    &:last-child {
      width: 100%;
      text-align: center;
    }
    .el-select,
    .el-date-editor {
      width: 100%;
    }
    .el-date-editor {
      .el-input__inner {
        padding-left: 15px;
      }
      .el-input__prefix {
        display: none;
      }
    }
  }
}
@media (max-width: 767px) {
  /deep/ .product-form {
    .el-form-item {
      width: 100%;
    }
  }
}
</style>